body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --box-width: 215px;
  --box-height: 383px;
  --box-border-radius: 5px;

  --box-info-width: 180px;
  --box-info-height: 370px;
  
  --box-info-description-height: 100px;
  --box-info-description-width: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom scroll */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(72, 72, 72);;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
}